export default {
    setAuth(state, auth) {
        state.hasAuth = auth;
    },

    setUserRouters(state, userRouters) {
        state.userRouters = userRouters;
    },

    setOriginRouters (state, routers) {
        state.originRouters = routers;
    },

    setUserInfo(state, userInfo) {
        state.permissions = userInfo.userPermission || [];
        state.menuList = userInfo.menuList || [];
        state.userId = userInfo.userId || userInfo.id;
        state.roleId = userInfo.roleId;
		state.isSuper = userInfo.isSuper;
        state.userName = userInfo.userName;
        state.schoolId = userInfo.schoolId;
        state.loginName = userInfo.loginName;
        state.isSuper = userInfo.isSuper;
        state.templateInfo = (typeof(userInfo.templateInfo) === 'string' && JSON.parse(userInfo.templateInfo)) || userInfo.templateInfo;
        state.teacherId = userInfo.teacherId;
        state.dealtNum = userInfo.dealtNum;
        state.userImg = userInfo.userImg;
        state.phone = userInfo.phone;
        state.addresUKey = userInfo.addresUKey;
        if (userInfo.projectInfo) {
            state.schoolLogo = userInfo.projectInfo.logo
            state.gradeLevelName = userInfo.projectInfo.gradeLevelName
            state.gradeLevel = userInfo.projectInfo.gradeLevel
            state.projectMark = userInfo.projectInfo.projectMark
            state.schoolStageList = userInfo.projectInfo.schoolStageList
            state.isTourClass = userInfo.projectInfo.isTourClass
            state.reportSchoolName = userInfo.projectInfo.schoolName
            state.schoolName = userInfo.projectInfo.schoolName
        }

        try {
            localStorage.isTourClass = JSON.stringify(state.isTourClass);
        } catch (e) {}

        try {
            localStorage.projectMark = JSON.stringify(state.projectMark)
        } catch (e) {}
    },

	setUserImg(state, userImg) {
		state.userImg = userImg
	},

    removeUserInfo(state, type) {
        state.hasAuth = type;
    },

    setTemplateStyle(state, data) {
        state.templateInfo = (typeof(data) === 'string' && JSON.parse(data)) || data;
    },

    addView: (state, view) => {
        if (state.visitedViews.some(v => v.path === view.path)) return
        if (view.meta && view.meta.title) {
            state.visitedViews.push(
                Object.assign({}, view, {
                    title: view.meta.title || ''
                })
            )
        }
    },

    delView: (state, view) => {
        for (const [i, v] of state.visitedViews.entries()) {
            if (v.path === view.path) {
                state.visitedViews.splice(i, 1)
                break
            }
        }
    },
    closeAllTabsMu(state, name) {
        state.visitedViews = state.visitedViews.filter(i => i.name === name);
    },
    updateView: (state, view) => {
        for (let v of state.visitedViews) {
            if (v.path === view.path) {
                v = Object.assign(v, view)
                break
            }
        }
    },
    setApprovalManagementGroupId(state, val) {
        state.approvalManagementGroupId = val;
        try {
            localStorage.approvalManagementGroupId = JSON.stringify(val);
        } catch (e) {
        }
    },
    // 存储OA审批路由传参
    setOAQueryData: (state, data) => {
        state.OAQueryData = data;
        try {
            localStorage.OAQueryData = JSON.stringify(state.OAQueryData);
        } catch (e) {
        }
    },
    // 存储课时统计查询条件
    setSlectData: (state, data) => {
        state.slectData = data;
    },
    // 待办数量
    setDealtNum: (state, data) => {
        state.dealtNum = data;
    },
    // 表单管理 保存左侧列表选中数据id
    setOAManagementLeftStatusIs(state, id) {
        state.oaManagementListLeftIds = id;

        try {
            localStorage.oaManagementListLeftIds = JSON.stringify(id)
        }catch(e) {}
    },
    changeDelItemStatus (state, status) {
        state.delItemStatus = status;
    },
    // 修改面包屑，强制去处某一项
    setVisitedViews (state, delData) {
        state.visitedViews = delData;
    },
    setNewsNum: (state, data) => {
        state.newsNum = data;
    },
    setSchoolName (state, data) {
        state.schoolName = data;
        try {
            localStorage.schoolName = state.schoolName;
        } catch (e) {
        }
    },
    setFeedbackNum(state, data) {
        state.feedbackNum = data;
    }
}
